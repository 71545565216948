<template>
  <secondary-page-layout title="Переоформление ОГПО">
    <div class="form-group px-3">
      <v-dialog />
      <div>

        <input-field
            :label="$t('enterPhoneNumber')"
            :is-block="true"
            field-type="tel"
            mask="+7(###) ###-##-##"
            v-model="phone_number"
            :disabled="true"
        />

        <add-person-info
            :persons="clients"
            :addBtnDisabled="personBtnIsDisabled"
            :addNewPersonInput="personAddNewInputShow"
            :personIin="clientIin"
            :error="clientError"
            @addNewField="personAddNewField"
            @input="validateIin"
            @delete="deletePerson"
            :is-reinsurance="true"
            :holder-id="holderId"
            :title="$t('drivers')"
            :button-title="$t('addDriver')"
        />

        <add-car-info
            :cars="cars"
            :addBtnDisabled="carsBtnIsDisabled"
            :addNewCarInput="carAddNewInputShow"
            :carNumber="carNumber"
            :error="carError"
            @addNewField="carAddNewField"
            @input="validateCarNumber"
            @delete="deleteCar"
            :title="$t('cars')"
            :button-title="$t('addCar')"
            ref="carInfoInput"
        />

        <radio-input-list
            :read-more="false"
            :options="monthsOptions"
            radio-name="periods"
            :value="period"
        />
        <select-date
            :is-disabled-before-today="true"
            :is-block="true"
            :customText="customText"
            :title="$t('agreementDate')"
            name="start_date"
            v-model="beginDate"
            :isDisable="true"
        />
        <input-field
            label="Email"
            field-type="email"
            :addition="$t('emailNotNecessary')"
            v-model="email"
            name="email"
            :is-block="true"
        />

        <reinsurance-products @updateSum="getFixSumByProduct"/>

        <ogpo-reinsurance-add-casco-lite />
<!--        <radio-input-list-->
<!--            v-if="bonusesOptions"-->
<!--            :read-more="true"-->
<!--            :options="bonusesOptions"-->
<!--            radio-name="bonuses"-->
<!--            :title="$t('selectBonusProduct')"-->
<!--            :value="bonusId"-->
<!--            @input="checkProducts"-->
<!--            @more="openModal"-->
<!--        />-->

        <div v-if="calculatedSum" class="final-amount-wrapper">
          <final-amount
              :amount="calculatedSum.sum"
              :fixSum="calculatedSum.fixedSum"
              :ogpo="fixSumParameters"
              @submit="proceedToPayment"
          />
        </div>
      </div>
    </div>
  </secondary-page-layout>
</template>

<script>
import AddPersonInfo from "/src/components/BuyPolicy/AddPersonInfo";
import AddCarInfo from "/src/components/BuyPolicy/AddCarInfo";
import SelectDate from "/src/components/BuyPolicy/SelectDate";
import RadioInputList from "/src/components/BuyPolicy/RadioInputList";
import InputField from "/src/components/BuyPolicy/InputField";
import FinalAmount from "/src/components/BuyPolicy/FinalAmount";
import BonusModal from "/src/components/Modals/BonusModal";
import {mapActions, mapGetters} from "vuex";
import ReinsuranceProducts from "../components/BuyPolicy/ReinsuranceProducts";
import ogpoFormMixin from "../mixin/ogpoFormMixin";
import redmine from "../mixin/redmine";
import moment from "moment";
import OgpoReinsuranceAddCascoLite from "../components/BuyPolicy/OgpoReinsuranceAddCascoLite";

export default {
  name: "OgpoReinsuranceForm",
  mixins: [ogpoFormMixin, redmine],
  components: {
    OgpoReinsuranceAddCascoLite,
    AddPersonInfo,
    AddCarInfo,
    RadioInputList,
    SelectDate,
    InputField,
    FinalAmount,
    ReinsuranceProducts
  },
  data() {
    return {
      storePrefix: 'reinsuranceForm',
      monthsOptions: [
        { name: this.$i18n.t('month12'), value: 12 },
      ],
    };
  },
  computed: {
    ...mapGetters({
      products: 'reinsuranceForm/products',
      productsRegion: 'reinsuranceForm/productsRegion',
      autoplusProduct: 'reinsuranceForm/autoplusProduct',
      availableProducts: 'reinsuranceForm/availableProducts',
      isLoyalClient: 'reinsuranceForm/isLoyalClient',
      minRiskRating: 'reinsuranceForm/minRiskRating',
      carYearAndCarType: 'reinsuranceForm/carYearAndCarType',
      clients: 'reinsuranceForm/clients',
      cars: 'reinsuranceForm/cars',
      endDate: 'reinsuranceForm/endDate',
      calculatedSum: 'reinsuranceForm/calculatedSum',
      addSumError: 'reinsuranceForm/addSumError',
      clientsChecked: 'reinsuranceForm/clientsChecked',
      casco: 'reinsuranceForm/casco',
      cascoReward: 'reinsuranceForm/cascoReward',
      form: 'reinsuranceForm/finalFormValue'
    }),
    isProduction() {
      return process.env.VUE_APP_ENVIRONMENT === 'production'
    },
    fixSumParameters() {
      const { calculatedSum, selectedProductId } = this;

      let params = [
        { name: this.$i18n.t('discount10'),
          price: `-${calculatedSum.saleOnline}`
        },
        {
          name: this.$i18n.t('autoplusCost'),
          price: calculatedSum.costProduct,
        },
        {
          name: this.$i18n.t('productDiscount'),
          price: `-${calculatedSum.saleCostProduct}`,
        },
        { name: this.$i18n.t('cascoLight'),
          price: calculatedSum.kasko
        },
        {
          name: this.$i18n.t('bonusSum' + selectedProductId),
          price: calculatedSum.bonusSum
        },
        {
          name: this.$i18n.t('remainingSum'),
          price: calculatedSum.remainingSum
        }
      ];
      return params.filter((p) => p.price != 0 || p.price != "-0");
    },
    holderId(){
      return this.agreement?.holder?.manID
    }
  },
  async created() {
    let loader = this.$loading.show({});

    await this.getOgpoProduct();

    await this.getAgreement();

    await this.resetForm();

    await this.setDefaultInfo();

    loader.hide();
  },

  mounted() {
    this.$watch('loyalClientModal', (needShow) => {if(needShow) { this.showLoyalClientModal(); } else { this.$modal.hide('dialog') }} )

    this.$watch('calculatedSum.fixedSum', (fixedSum) => {
      const fixedBool = !!fixedSum;
      if(!fixedBool) {
        return
      }
      if(!!fixedBool && fixedSum <= 0) {
        this.visitBranchModal();
      } else {
        this.$modal.hide('dialog')
      }
    })
  },



  methods: {
    ...mapActions({
      changePhoneNumber: 'reinsuranceForm/changePhoneNumber',
      getPeopleByIin: 'reinsuranceForm/getPeopleByIin',
      getCarByLicensePlate: 'reinsuranceForm/getCarByLicensePlate',
      getProducts: 'reinsuranceForm/getProducts',
      deletePerson: 'reinsuranceForm/deletePerson',
      deleteCar: 'reinsuranceForm/deleteCar',
      isHaveBasketFriend: 'reinsuranceForm/isHaveBasketFriend',
      changeSelectedProductId: 'reinsuranceForm/changeSelectedProductId',
      changeSelectedProductName: 'reinsuranceForm/changeSelectedProductName',
      getFixSum: 'reinsuranceForm/getFixSum',
      getFixSumByProduct: 'reinsuranceForm/getFixSumByProduct',
      checkClientsDoc: `reinsuranceForm/checkClientsDoc`,
      setTempPolicy: 'reinsuranceForm/setTempPolicy',
      sumVerification: 'reinsuranceForm/sumVerification',
      getRemainingSum: 'reinsuranceForm/getRemainingSum',
      saveDraft: 'reinsuranceForm/saveDraft',
      resetForm: 'reinsuranceForm/resetForm',
      getCascoLite: 'reinsuranceForm/getCascoLite',
    }),
    visitBranchModal() {
      this.$modal.show('dialog', {
        title: this.$i18n.t('dearClient'),
        text: this.$i18n.t('visitBranchToSign'),
        buttons: [{
          title: 'OK',
          handler: () => {
            this.$modal.hide('dialog')
          }
        }, {
          title: 'Список филиалов',
          handler: () => {
            this.$modal.hide('dialog')
            this.$router.replace({name:'about.us'})
          }
        }]
      });
    },
    async getAgreement() {
      const {data} = await window.axios.get(`policies/${this.$route.params.id}`);

      if(![11, 12].includes(data.state) && this.isProduction) {
        await this.$router.push('/agreements');
      }

      const beginDate = moment(data.beginDate.date);

      if (beginDate > moment() && this.isProduction) {
        await this.$router.push('/agreements');
      }

      this.agreement = data;

      this.$store.commit('reinsuranceForm/SET_AGREEMENT_ID', data.id);
    },

    async getOgpoProduct() {
      const {data} = await window.axios.get('insurance-types/1');
      this.$store.commit("SET_INSURANCE_PRODUCT", data.products[0]);
    },

    async setDefaultInfo(){
      this.phone_number = `7${this.authUser.phone_number}`

      this.changePhoneNumber(this.authUser.phone_number)

      const {insureds, objects} = this.agreement;

      for(let i = 0; i < insureds.length; i++) {
        await this.validateIin(insureds[i].bin)
        if(this.agreement.holder.bin === insureds[i].bin) {
          const payload = {
            client: {...this.clients[i], mainClient: true},
            index: i
          }
          this.$store.commit('reinsuranceForm/REPLACE_CLIENT', payload)
        }
      }

      for(let i = 0; i < objects.length; i++) {
        await this.validateCarNumber(objects[i].license_plate)
      }
    },

    async recalculate() {
      if(this.clientChecking) {
        return false;
      }

      this.$store.commit("reinsuranceForm/SET_CALCULATED_SUM", null);

      const { cars, clients, $loading } = this;

      if(cars.length === 0 || clients.length === 0) {
        this.selectedProductId = 0
        this.$store.commit("reinsuranceForm/SET_PRODUCTS",  []);
        return
      }

      const loader = $loading.show({});

      await this.getCascoLite();
      await this.getProducts();

      if (this.selectedProductId === 0 && this.products.length > 0) {
        const [selectedProduct] = this.products

        this.selectedProductId = selectedProduct["mergeProduct"]["id"]
      }

      await this.getFixSum();
      await this.getRemainingSum();
      await this.getFixSumByProduct();

      loader.hide()
    },

    async proceedToPayment() {
      const { addSumError, cars, clients, period, selectedProductId, phone_number } = this

      const loader = this.$loading.show();

      this.clientChecking = true

      try{
        await this.checkClientsDoc();
      } catch (e) {
        this.showClientCheckModal();
        return loader.hide();
      }
      this.clientChecking = false;

      if (addSumError) {
        await this.recalculate();

        loader.hide();
        return;
      }

      if (cars.length === 0 || clients.length === 0) {
        loader.hide();
        return
      }

      if(period < 12 && selectedProductId !== 0 && selectedProductId !== 10) {
        this.$store.commit("reinsuranceForm/SET_ADDSUMERROR", "Выбран некорректный баскет");
      }

      if (selectedProductId === 16){
        this.$store.commit("reinsuranceForm/SET_FRIEND_BASKET", false);
      }

      var tempPhone = phone_number.replace(/[()+\- ]/g, "");

      //phone number verification
      if (!tempPhone.match(/^7\d{9}$/g)) {
        loader.hide();
        this.errorMessage = this.$i18n.t("sumStep.errors.phoneError");
        return;
      }

      try{
        await this.setTempPolicy();
      } catch (e) {
        loader.hide();
        this.$modal.show('dialog', {
          title: 'Произошла ошибка',
          text: e?.response?.message || e?.message,
          buttons: [{
            title: 'OK',
            handler: () => {
              this.$modal.hide('dialog')
              return null;
            }
          }]
        }, {class: 'error'});

        this.onError({
          message: e?.response?.message || e?.message || 'Ошибка при создании полиса',
          code: e?.statusCode || 0,
          title: `Ошибка при создании полиса`,
        });

        return;
      }

      try{
        await this.sumVerification();
      }catch (e) {
        loader.hide();
        this.$modal.show('dialog', {
          title: 'Произошла ошибка',
          text: e?.response?.message || e?.message,
          buttons: [{
            title: 'OK',
            handler: () => {
              this.$modal.hide('dialog')
              return null;
            }
          }]
        }, {class: 'error'});

        this.onError({
          message: e?.response?.message || e?.message || 'Ошибка при сверке суммы',
          code: e?.statusCode || 0,
          title: `Ошибка при сверке суммы`,
        });

        return;
      }

      loader.hide();

      await this.$router.push({
        name: "ogpo-reinsurance.form.confirm",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/sass/variables";

</style>
